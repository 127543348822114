import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";

import { AppPageProps } from "../../router";
import env from "../../utils/env";
import { ObjectHash } from "../../utils/helpers";
import useModal from "../../hooks/use-modal.hook";

import GridModel from "../../models/grid.model";
import TravelQueryModel, {
  TravelQueryParam
} from "../../models/travel-query.model";
import {
  TravelFilterFields,
  TravelFilterResources,
  TravelTypeKeys
} from "../../models/travel-filter.model";

import AutocompleteInput from "../../components/form/inputs/AutocompleteInput";
import AutocompleteInputCheckbox from "../../components/form/inputs/AutocompleteInputCheckbox";
import BuildTravelButton from "../../components/button/build-travel";
import Button from "../../components/button";
import CopyEmailForwardButton from "../../components/button/copy-email-forward";
import PageHeader from "../../components/page-header";
import ParsingEmails from "../../components/parsing-emails";
import TravelFilterMenu from "../../components/travel-filter-menu";
import TravelQueryTable from "../../components/travel-query-table";

import "./dashboard-page.scss";

export default function DashboardPage(props: AppPageProps) {
  const { company, settings } = props;
  const history = useHistory();
  const { openModal } = useModal();
  const companyEmail = company.getEmailForAppDomain(env.mailboxDomain);

  const defaultQuery = new TravelQueryModel();

  const isNflDashboard = Boolean(company.features.nflDashboard);
  const isGridsFeatureActive = Boolean(company.features.grids);

  if (!isGridsFeatureActive || isNflDashboard) {
    // default filter, show all upcoming travel
    const afterDateParam = new TravelQueryParam({
      field: TravelFilterFields.TravelDateAfter,
      resource: TravelFilterResources.Trip,
      value: DateTime.local().minus({ days: 1 }).toFormat("yyyy-MM-dd"),
      position: 1
    });

    defaultQuery.params.push(afterDateParam);
  }

  const tripTypeOpts = [
    { value: "AIR", label: "Flights" },
    { value: "CAR", label: "Transportation" },
    { value: "HOTEL", label: "Accommodations" },
    { value: "RAIL", label: "Rail" }
  ];

  const [dashboardQuery, setDashboardQuery] = useState<TravelQueryModel>(
    defaultQuery
  );
  const [travelType, setTravelType] = useState<TravelTypeKeys>("air");

  const onChangeFilter = (travelQuery: TravelQueryModel) => {
    setDashboardQuery(travelQuery);
  };

  const onChangeTripType = (value: TravelTypeKeys) => {
    setTravelType(value);
  };

  const onSavegGrid = () => {
    openModal("edit-grid", {
      travelQuery: dashboardQuery,
      onChangeGrid: (grid: GridModel) => {
        history.push(`/grids/${grid.id}`);
      }
    });
  };

  const hasFreezeDate = settings.dataFreezeDate && company.features?.dataFreeze;
  const viewOpts = [];

  if (hasFreezeDate) {
    viewOpts.push({ label: "Frozen Travel", value: "frozen", isChecked: true });
    viewOpts.push({
      label: "Cancelled Travel",
      value: "cancelled",
      isChecked: false
    });
  }
  const [activeViewOpts, setActiveViewOpts] = useState<ObjectHash[]>(viewOpts);

  const onViewOptChange = (value: string) => {
    const updatedViewOpts = activeViewOpts.map((viewOpt: ObjectHash) => {
      if (viewOpt.value === value) {
        viewOpt.isChecked = !viewOpt.isChecked;
      }
      return viewOpt;
    });
    setActiveViewOpts(updatedViewOpts);
  };

  const hasViewOptions = Boolean(activeViewOpts.length);
  const useDataFreezeDate = !activeViewOpts.find(
    (opt: ObjectHash) => opt.value === "frozen"
  )?.isChecked;
  const queryHasParams = Boolean(dashboardQuery.params.length);

  return (
    <div id="dashboard-page" className="page-container">
      <PageHeader title={company.name} />
      <ParsingEmails />

      <div className="table-tools">
        <div className="filters">
          <AutocompleteInput
            defaultValue={tripTypeOpts[0]}
            isSearchable={false}
            options={tripTypeOpts}
            onChange={onChangeTripType}
          />

          <TravelFilterMenu
            travelQuery={dashboardQuery}
            travelType={travelType}
            onChange={onChangeFilter}
            placement="bottom-start"
          />
          {queryHasParams && isGridsFeatureActive && !isNflDashboard && (
            <Button
              color="product-blue"
              label="Save as New Grid"
              onClick={() => onSavegGrid()}
              size="medium"
            />
          )}
        </div>
        <div className="buttons">
          {hasViewOptions && (
            <div className="view-options">
              <AutocompleteInputCheckbox
                onChange={(value: string) => {
                  onViewOptChange(value);
                }}
                options={activeViewOpts}
                placeholder="View Options"
              />
            </div>
          )}
          <CopyEmailForwardButton email={companyEmail} />
          <BuildTravelButton />
        </div>
      </div>
      <TravelQueryTable
        key={`trips-${travelType}`}
        travelQuery={dashboardQuery}
        travelType={travelType}
        useDataFreezeDate={useDataFreezeDate}
      />
    </div>
  );
}
