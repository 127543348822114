import React from "react";
import TagChip from "../tag-chip";
import TagModel from "../../models/tag.model";
import TagGroupModel from "../../models/tag-group.model";
import SelectToggleMenu, { SelectOption } from "../select-toggle-menu";

export interface Props {
  isDisabled?: boolean;
  menuPortalTarget?: any;
  multiSelect?: boolean;
  onChange: CallableFunction;
  searchPlaceholder?: string;
  selectedTagIds?: string[];
  tagGroup: TagGroupModel;
  width?: number | string;
}

export default function TagSelect(props: Props) {
  const {
    isDisabled,
    onChange,
    menuPortalTarget,
    multiSelect,
    searchPlaceholder,
    tagGroup,
    width
  } = props;

  let { selectedTagIds } = props;

  const filterUnconfirmed = (options: SelectOption[]) => {
    return options.filter(
      (opt: SelectOption) => !(opt as TagModel).unconfirmed
    );
  };

  return (
    <SelectToggleMenu
      isDisabled={isDisabled}
      menuPortalTarget={menuPortalTarget}
      multiSelect={multiSelect}
      onChange={(options: SelectOption[]) => onChange(options, tagGroup)}
      onFilterOptions={filterUnconfirmed}
      onOption={(opt: SelectOption) => <TagChip tag={opt as TagModel} />}
      options={tagGroup.tags.sort((aTag: TagModel, bTag: TagModel) => {
        return aTag.name > bTag.name ? 1 : -1;
      })}
      placeholder="Select Tags"
      searchPlaceholder={searchPlaceholder}
      selectedOptionIds={selectedTagIds}
      width={width}
    />
  );
}
